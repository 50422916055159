<template>
    <v-app>
        <v-dialog v-model="dialog" max-width="800">
            <v-card>
                <v-card-title>
                    {{edit? 'Update' :'Add'}} Category
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col>
                            <v-text-field label="Title" v-model="member.full_name" outlined dense>

                            </v-text-field>
                            <span class="text-danger" v-if="$v.member.full_name.$error">Title is required</span>

                        </v-col>
                        <v-col>
                            <v-switch label="Active" v-model="member.is_active">

                            </v-switch>
                        </v-col>
                        <v-col>
                            <button class="btn btn-primary" @click="createOrUpdate"> {{edit? 'Edit' :'Add'}}</button>
                        </v-col>
                    </v-row>
                    <v-row>
                        <table class="table mt-3">
                            <thead class="text-left">
                            <th>Title</th>
                            <th>Action</th>
                            </thead>

                            <draggable @change="sort" v-model="members" class="text-left" tag="tbody" handle=".handle">
                            <tr v-for="(member, index) of members" :key="member.full_name">
                                <td scope="row">
                                    <i class="cursor-drag fas fa-sort handle pr-2"></i>
                                    {{ member.full_name }}
                                    <i class="fas fa-circle"
                                       :class="member.is_active? 'dot-active' : 'dot-inactive'"></i></td>

                                <td>
                                    <b-dropdown
                                            size="sm"
                                            variant="link"
                                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                            no-caret
                                            right
                                            no-flip
                                    >
                                        <template v-slot:button-content>
                                            <slot>
                                                        <span>
                                                                    <i class="flaticon-more-1"></i>
                                                                  </span></slot>
                                        </template>
                                        <!--begin::Navigation-->
                                        <div class="navi navi-hover ">

                                            <!--                                                  <b-dropdown-text tag="div" class="navi-item">-->
                                            <!--                                                    <a href="#" class="navi-link" @click="detail(member.id)">-->
                                            <!--                                                            <span class="navi-icon">-->
                                            <!--                                                              <i class="fas fa-eye"></i>-->
                                            <!--                                                            </span>-->
                                            <!--                                                      <span class="navi-text"> View </span>-->
                                            <!--                                                    </a>-->
                                            <!--                                                  </b-dropdown-text>-->
                                            <b-dropdown-text tag="div" class="navi-item">
                                                <a href="#" class="navi-link" @click="editMember(member)">
                                                            <span class="navi-icon">
                                                              <i class="flaticon-edit"></i>
                                                            </span>
                                                    <span class="navi-text"> Edit </span>
                                                </a>
                                            </b-dropdown-text>

                                            <b-dropdown-text tag="div" class="navi-item">
                                                <a href="#" class="navi-link"
                                                   @click="deleteMember(member.id)">
                                                            <span class="navi-icon">
                                                              <i class="fas fa-trash"></i>
                                                            </span>
                                                    <span class="navi-text">  Delete</span>
                                                </a>
                                            </b-dropdown-text>

                                        </div>
                                        <!--end::Navigation-->
                                    </b-dropdown>

                                </td>
                            </tr>
                            </draggable>
                        </table>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-app>

</template>
<script>
    import {required} from "vuelidate/lib/validators";
    import MemberService from "@/core/services/member/MemberService";
    const memberService = new MemberService();
    import draggable from 'vuedraggable'
    import CreateAndUpdate from "./CreateAndUpdate";
    export default {
        validations: {
            member: {
                full_name: {required}
            }
        },
        components: {
            draggable
        },
        data() {
            return {
                search: {
                    is_parent: 1,
                },
                dialog: false,
                edit: false,
                sizeExceed: false,
                isBusy: false,
                member: {
                    full_name: null,
                    is_parent: 1,
                    is_active: true
                },
                members: []
            };
        },
        methods: {
            getMembers() {
                memberService
                    .paginate(this.search)
                    .then(response => {
                        this.members = response.data.data;
                    })
                    .catch(error => {
                        // console.log(error);
                    });
            },
            showModal(id = null) {
                if (id) {
                    this.edit = true
                    this.getMember(id);

                }
                this.dialog = true;
                this.getMembers();
                // this.$refs["my-modal"].show();
            },
            hideModal() {
                this.dialog = false;
            },
            toggleModal() {
                this.$refs["my-modal"].toggle("#toggle-btn");
            }, getMember(id) {
                memberService.show(id).then(response => {
                    this.member = response.data.member
                    if (this.member.tags) {
                        this.member.tags = this.member.tags.split(',');
                    }
                })
            },
            editMember(item) {
                this.edit = true;
                this.member = item;
            },
            generateFd() {
                // console.log(this.member)
                let fd = new FormData();
                for (let key in this.member) {
                    if (key == "is_active" && this.member["is_active"]) {
                        fd.append("is_active", this.member ? 1 : 0);
                    } else {
                        if (this.member[key] != null) {
                            fd.append(key, this.member[key]);
                        }
                    }
                }
                return fd;
            },
            createOrUpdate() {
                this.$v.member.$touch();
                if (this.$v.member.$error) {
                    setTimeout(() => {
                        this.$v.member.$reset();
                    }, 3000);
                } else {
                    let fd = this.member;
                    if (this.edit) {
                        this.updateFaqCategory(fd);
                    } else {
                        this.createMemberCategory(fd);
                    }
                }
            },
            updateFaqCategory(fd) {
                this.isBusy = true;
                memberService
                    .update(this.member.id, fd)
                    .then(response => {
                        this.isBusy = false;
                        this.$snotify.success("Information updated");

                        this.resetForm();
                    })
                    .catch(error => {
                        this.isBusy = false;
                        this.$snotify.error("Something Went Wrong");
                        // console.log(error);
                    });
            },

            checkSize(file) {
                let size = file.size / 1024 / 1024;
                let mbSize = size.toFixed(2);
                if (mbSize > 20) {
                    this.sizeExceed = true;
                } else {
                    this.sizeExceed = false;
                }
            },
            deleteMember(id) {
                this.$confirm({
                    message: `Are you sure you want to delete this item?`,
                    button: {
                        no: "No",
                        yes: "Yes"
                    },
                    /**
                     * Callback Function
                     * @param {Boolean} confirm
                     */
                    callback: confirm => {
                        if (confirm) {
                            memberService
                                .delete(id)
                                .then(response => {
                                    this.getMembers();
                                })
                                .catch(error => {
                                    //console.log(error);
                                });
                        }
                    }
                });
            },
            sort() {
                memberService.sort(this.members).then((res) => {
                    this.$snotify.success("Sorted");
                    this.getMembers()
                }).catch(err => {
                    this.$snotify.success("Sorted Error");
                })
            },
            createMemberCategory(fd) {
                this.isBusy = true;
                memberService
                    .store(fd)
                    .then(response => {
                        this.isBusy = false;
                        this.resetForm();
                        this.$snotify.success("Information added");

                    })
                    .catch(error => {
                        this.isBusy = false;
                        this.$snotify.error("Something Went Wrong");
                        // console.log(error);
                    });
            },
            resetForm() {
                this.$v.member.$reset();
                this.edit = false;
                this.member = {
                    full_name: null,
                    is_active: 1,
                    is_parent: 1,
                };
                this.hideModal();
                this.$emit("refresh_member");
            }
        }
    };
</script>
