<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Manage our people category</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \ Our people category
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn
                    @click="addOurPeople()"
                    class="mt-1 btn btn-primary"
                    style="color: #fff"
                  >
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp; Add
                    our people category
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div
              class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start"
            >
              <div class="row">
                <v-col cols="12" md="4">
                  <v-text-field
                    dense
                    label="Title"
                    outlined
                    type="text"
                    v-model="search.title"
                  />
                </v-col>

                <v-col cols="12" md="3">
                  <v-select
                    :items="is_linked_courses"
                    dense
                    item-text="title"
                    item-value="value"
                    label="Is linked with program"
                    outlined
                    v-model="search.is_linked_program"
                  ></v-select>
                </v-col>

                <v-col cols="12" md="2">
                  <v-select
                    :items="active_statuses"
                    dense
                    item-text="title"
                    item-value="value"
                    label="Status"
                    clearable
                    outlined
                    v-model="search.active"
                  ></v-select>
                </v-col>

                <v-col cols="12" md="3" class="text-right">
                  <v-btn
                    @click.prevent="getOurPeople"
                    class="btn btn-primary w-35"
                    style="color: #fff"
                    :loading="loading"
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>
            </div>
            <div class="">
              <table class="table mt-3">
                <thead class="text-left">
                  <tr class="px-3">
                    <th class="wrap-column px-3">Title</th>
                    <th class="wrap-column px-3">Description</th>
                    <th class="wrap-column px-3">Display type</th>
                    <th class="wrap-column px-3">Is linked program</th>
                    <th class="wrap-column px-3">Display on  website</th>
                    <th class="wrap-column px-3">Action</th>
                  </tr>
                </thead>

                <draggable
                  v-show="ourPeoples.length > 0"
                  @change="sort"
                  class="text-left"
                  handle=".handle"
                  tag="tbody"
                  v-model="ourPeoples"
                >
                  <tr :key="people.id" v-for="(people, index) of ourPeoples">
                    <td class="wrap-column px-3" scope="row">
                      <i class="cursor-drag fas fa-sort handle pr-2"></i>
                      <a @click="editMember(people.id)" class="mr-2" href="#">
                        {{ people.title }}</a
                      >
                      <i
                        :class="people.is_active ? 'dot-active' : 'dot-inactive'"
                        class="fas fa-circle"
                      ></i>
                    </td>
                    <td class="wrap-column px-3">
                      <p v-if="people.description">
                        {{ people.description | truncate(120, "...") }}
                      </p>
                      <p v-else>N/A</p>
                    </td>
                    <td class="wrap-column px-3 pt-3">
                      <span class="badge badge-primary">{{
                        people.formatted_display_type
                          ? people.formatted_display_type
                          : null
                      }}</span>
                    </td>
                    <td class="wrap-column px-3 pt-3">
                      <span
                        :class="
                          people.is_linked_program ? 'badge-success' : 'badge-danger'
                        "
                        class="badge btn-circle"
                      >
                        {{ people.is_linked_program ? "Yes" : "No" }}</span
                      ><br />
                    </td>
                    <td class="wrap-column px-3 pt-3">
                      <span
                        :class="people.is_active ? 'badge-success' : 'badge-danger'"
                        class="badge text-lg"
                      >
                        {{ people.is_active ? "Yes" : "No" }}</span
                      ><br />
                    </td>

                    <td class="wrap-column px-3">
                      <b-dropdown
                        no-caret
                        no-flip
                        right
                        size="sm"
                        toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                        variant="link"
                      >
                        <template v-slot:button-content>
                          <slot>
                            <span>
                              <i class="flaticon-more-1"></i> </span
                          ></slot>
                        </template>
                        <!--begin::Navigation-->
                        <div class="navi navi-hover">
                          <!--                                                  <b-dropdown-text tag="div" class="navi-item">-->
                          <!--                                                    <a href="#" class="navi-link" @click="detail(people.id)">-->
                          <!--                                                            <span class="navi-icon">-->
                          <!--                                                              <i class="fas fa-eye"></i>-->
                          <!--                                                            </span>-->
                          <!--                                                      <span class="navi-text"> View </span>-->
                          <!--                                                    </a>-->
                          <!--                                                  </b-dropdown-text>-->
                          <b-dropdown-text class="navi-item" tag="div">
                            <a @click="editMember(people.id)" class="navi-link" href="#">
                              <span class="navi-icon">
                                <i class="flaticon-edit"></i>
                              </span>
                              <span class="navi-text"> Edit </span>
                            </a>
                          </b-dropdown-text>

                          <b-dropdown-text class="navi-item" tag="div">
                            <a
                              @click="deleteMember(people.id)"
                              class="navi-link"
                              href="#"
                            >
                              <span class="navi-icon">
                                <i class="fas fa-trash"></i>
                              </span>
                              <span class="navi-text"> Delete</span>
                            </a>
                          </b-dropdown-text>
                        </div>
                        <!--end::Navigation-->
                      </b-dropdown>
                    </td>
                  </tr>
                </draggable>
                <tr v-if="ourPeoples.length == 0">
                  <td colspan="6" class="text-center"><strong>Data not available</strong></td>
                </tr>
              </table>
              <b-pagination
                v-if="ourPeoples.length > 0"
                class="pull-right mt-7"
                :per-page="perPage"
                :total-rows="total"
                @input="getOurPeople"
                first-number
                last-number
                v-model="page"
              ></b-pagination>
            </div>
            <div
              class="wizard wizard-2"
              data-wizard-clickable="true"
              data-wizard-state="step-first"
              id="kt_wizard_v2"
            >
              <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
                <!--begin: Wizard Form-->

                <div class="row">
                  <div class="col-12" v-if="total > perPage">
                    <!--  -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <create-and-update
            @refresh_our_people="getOurPeople"
            ref="our-people"
          ></create-and-update>
          <manage-category
            @refresh_our_people="getOurPeople"
            ref="manageCategory"
          ></manage-category>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CreateAndUpdate from "./CreateAndUpdate";
import ManageCategory from "./ManageCategory";
import draggable from "vuedraggable";
import OurPeopleService from "@/core/services/our-people/OurPeopleService";

const peopleService = new OurPeopleService();
export default {
  name: "people",
  display: "Table",
  order: 8,
  components: {
    CreateAndUpdate,
    ManageCategory,
    draggable,
  },
  data() {
    return {
      active_statuses: [
        { title: "Active", value: "active" },
        { title: "Inactive", value: "in_active" },
      ],
      is_linked_courses: [
        { title: "Yes", value: true },
        { title: "No", value: false },
      ],
      ourPeoples: [],
      categories: [],
      page: null,
      perPage: null,
      total: null,
      search: {
        active: "active",
      },
      dragging: false,
      loading: false,
    };
  },
  mounted() {
    this.getOurPeople();

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Faq", route: "wizard-1" },
      { title: "Faq" },
    ]);
  },

  methods: {
    getOurPeople() {
      this.loading = true;
      peopleService
        .paginate(this.search, this.page)
        .then((response) => {
          this.ourPeoples = response.data.data;
          this.page = response.data.meta.current_page;
          this.total = response.data.meta.total;
          this.perPage = response.data.meta.per_page;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          // console.log(error);
        });
    },
    __reset() {
      this.search = null;
      this.search = {
        active: "active",
      };
      this.getOurPeople();
    },

    addOurPeople() {
      this.$refs["our-people"].showModal();
    },
    editMember(id) {
      this.$refs["our-people"].showModal(id);
    },
    manageCategory() {
      this.$refs["manageCategory"].showModal();
    },

    deleteMember(id) {
      this.$confirm({
        message: `Are you sure you want to delete this item?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            peopleService
              .delete(id)
              .then((response) => {
                this.getOurPeople();
              })
              .catch((error) => {
                //console.log(error);
              });
          }
        },
      });
    },
    sort() {
      peopleService
        .sort(this.ourPeoples)
        .then((res) => {
          this.$snotify.success("Sorted");
          this.getOurPeople();
        })
        .catch((err) => {
          this.$snotify.success("Sorted Error");
        });
    },
    detail(id) {
      this.$router.push({ name: "member-photo", params: { id: id } });
    },
  },
};
</script>
<style scoped>
.buttons {
  margin-top: 35px;
}
</style>
