<template>
  <v-app>
    <v-dialog max-width="800" v-model="dialog">
      <v-card>
        <v-toolbar dark>
          <v-card-title class="headline">
            {{ `${edit ? 'Update' : 'Add'}` }} our people category
            <hr>
          </v-card-title>

          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon  @click="cancelForm">
              <i class="fas fa-times"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="8">
              <v-text-field dense label="Title" outlined v-model="ourPeople.title">
              </v-text-field>
              <span class="text-danger" v-if="$v.ourPeople.title.$error">This information is required</span>
            </v-col>
            <v-col cols="4">
              <v-select label="Display type" :items="display_types" v-model="ourPeople.display_type" item-text="title" item-value="value" outlined dense></v-select>
            </v-col>
            <v-col cols="12">
              <v-textarea dense label="Description" outlined v-model="ourPeople.description">
              </v-textarea>

            </v-col>
            <v-col cols="4">
              Display on front website
              <v-switch dense :label="ourPeople.is_active ? 'Yes' : 'No'" outlined v-model="ourPeople.is_active">
              </v-switch>

            </v-col>
            <v-col cols="4">
              Linked program
              <v-switch dense :label="ourPeople.is_linked_program ? 'Yes' : 'No'"  outlined v-model="ourPeople.is_linked_program">
              </v-switch>
            </v-col>
            <v-col cols="4">
              Linked subjects
              <v-switch dense :label="ourPeople.is_linked_subject ? 'Yes' : 'No'"  outlined v-model="ourPeople.is_linked_subject">
              </v-switch>
            </v-col>
            <v-col cols="4">
              Linked with staff designation
              <v-switch dense :label="ourPeople.is_linked_designation ? 'Yes' : 'No'"  outlined v-model="ourPeople.is_linked_designation">
              </v-switch>
            </v-col>
            <v-col cols="4" v-if="ourPeople.is_linked_designation">
              <v-select multiple outlined dense v-model="ourPeople.staff_designation" :items="designations" item-value="value" item-text="title" label="Staff designation"></v-select>
            </v-col>

            <v-col cols="12">
              <div class="text-right float-right">
                <v-btn @click="cancelForm" class="text-gray btn btn-standard  cancel-btn" depressed>
                  Cancel
                </v-btn>
                <v-btn :loading="isBusy" @click="createOrUpdate" class="text-white ml-2 btn btn-primary "
                       depressed>
                  Save
                </v-btn>
              </div>

            </v-col>
          </v-row>

        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app>

</template>
<script>
import {required} from "vuelidate/lib/validators";
import OurPeopleService from "@/core/services/our-people/OurPeopleService";

const ourPeopleService = new OurPeopleService;
export default {
  name: 'ourPeoples',
  validations: {
    ourPeople: {
      title: {required},
      is_active: {required},
    }
  },
  data() {
    return {
      programs: [],
      display_types: [
        {title:"Default", value:"default"},
        {title:"Categorised listing", value:"categorised_listing"},
      ],
      designations: [
        {title:"Account", value:'account'},
        {title:"Administration", value:'administration'},
        {title:"Creative", value:'creative'},
        {title:"Discipline", value:'discipline'},
        {title:"ECA", value:'eca'},
        {title:"Examination", value:'examination'},
        {title:"Front Desk", value:'front_desk'},
        {title:"Guidance & counseling", value:'guidance_&_counseling'},
        {title:"Hostel", value:'hostel'},
        {title:"Human resource", value:'human_resource'},
        {title:"IT", value:'it'},
        {title:"Laboratory", value:'laboratory'},
        {title:"Legal", value:'legal'},
        {title:"Library", value:'library'},
        {title:"Nursing", value:'nursing'},
        {title:"Support staff", value:'support_staff'},
        {title:"Transportations", value:'transportations'},
        {title:"Admission & student services", value:'admission_&_student_services'},
      ],
      dialog: false,
      edit: false,
      sizeExceed: false,
      isBusy: false,
      search: {
        active: "active",
        is_parent: 1,
      },
      ourPeople: {
        title: null,
        display_type: "default",
        description: null,
        parent_id: null,
        is_active: true,
        is_linked_program: true,
        is_linked_subject: true,
      },
      ourPeople_categories: ['test'],
    };
  },
  mounted() {

  },
  methods: {
    cancelForm(){
      this.resetForm()
      this.hideModal()
    },
    getPrograms() {
      this.$bus.emit('toggleLoader',);
      programService.all().then(response => {
        this.programs = response.data;
      }).catch((err) => {
        // console.log(err)
      }).finally(() => {
        this.$bus.emit('toggleLoader');
      });
    },
    showModal(id = null) {
      if (id) {
        this.edit = true
        this.getOurPeoples(id)
      }else{
        this.resetForm()
      }
      this.dialog = true;
      // this.getFacultyMemberCategories();
      // this.getPrograms();
      // this.$refs["my-modal"].show();
    },
    hideModal() {
      this.dialog = false;
    },
    toggleModal() {
      this.$refs["my-modal"].toggle("#toggle-btn");
    },
    getOurPeoples(id) {
      ourPeopleService.show(id).then(response => {
        this.ourPeople = response.data.ourPeople

      })
    },
    getFacultyMemberCategories() {
      ourPeopleService
          .paginate(this.search)
          .then(response => {
            this.ourPeople_categories = response.data.data;
          })
          .catch(error => {
            // console.log(error);
          });
    },
    generateFd() {
      // console.log(this.ourPeople)
      let fd = new FormData();
      for (let key in this.ourPeople) {
        if (key == "is_active" && this.ourPeople["is_active"]) {
          fd.append("is_active", this.ourPeople ? 1 : 0);
        } else {
          if (this.ourPeople[key] != null) {
            fd.append(key, this.ourPeople[key]);
          }
        }
      }
      return fd;
    },
    createOrUpdate() {
      this.$v.ourPeople.$touch();
      if (this.$v.ourPeople.$error) {
        setTimeout(() => {
          this.$v.ourPeople.$reset();
        }, 3000);
      } else {
        // if (this.ourPeople.program_ids)
        //     this.ourPeople.program_ids = this.ourPeople.program_ids.join(',');
        let fd = this.ourPeople;
        if (this.edit) {
          this.__update(fd);
        } else {
          this.__create(fd);
        }
      }
    },
    __update(fd) {
      this.isBusy = true;
      ourPeopleService
          .update(this.ourPeople.id, fd)
          .then(response => {
            this.isBusy = false;
            this.$snotify.success("Information updated");
            this.resetForm();
            this.hideModal();
            this.$emit("refresh_our_people");
          })
          .catch(error => {
            this.isBusy = false;
            this.$snotify.error("Something Went Wrong");
            // console.log(error);
          });
    },

    checkSize(file) {
      let size = file.size / 1024 / 1024;
      let mbSize = size.toFixed(2);
      if (mbSize > 20) {
        this.sizeExceed = true;
      } else {
        this.sizeExceed = false;
      }
    },
    __create(fd) {
      this.isBusy = true;
      ourPeopleService
          .store(fd)
          .then((response) => {
            // console.log(response)
            this.isBusy = false;
            this.resetForm();
            this.$snotify.success("Information added");
            this.hideModal();
            this.$emit("refresh_our_people");
          })
          .catch(error => {
            this.isBusy = false;
            this.$snotify.error("Something Went Wrong");
            // console.log(error);
          });
    },
    resetForm() {
      this.$v.ourPeople.$reset();
      this.edit = false;
      this.ourPeople = {
        title: null,
        is_active: true
      };
    }
  }
};
</script>
